import {Component, OnInit} from '@angular/core';
import {HeaderTextService} from '../../../services/header-text.service';
import {ActivatedRoute} from '@angular/router';
import {MatTabChangeEvent, MatTabsModule} from '@angular/material/tabs';
import {Destroyed} from '../../shared/directives/destroyed.directive';
import {HomeFileManagerComponent} from '../home-file-manager/home-file-manager.component';

@Component({
  selector: 'app-file-manager-tabs',
  templateUrl: './file-manager-tabs.component.html',
  styleUrls: ['./file-manager-tabs.component.scss'],
  standalone: true,
  imports: [MatTabsModule, HomeFileManagerComponent]
})
export class FileManagerTabsComponent extends Destroyed implements OnInit {
  title = 'Documents';
  defaultIndex = 0;
  selectedTab;

  tabLoadTimes: Date[] = [];

  constructor(
    private readonly headerTextService: HeaderTextService,
    private readonly route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    this.headerTextService.setTitle(this.title);
    this.route.params.pipe(this.untilDestroyed()).subscribe((param) => {
      if (param.id && param.id > 0) {
        this.defaultIndex = param.id - 1;
        this.selectedTab = param.id;
      }
    });
  }

  tabChanged($event: MatTabChangeEvent) {
    this.selectedTab = $event.index + 1;
  }
}
