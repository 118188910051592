import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.modules';
import {FileManagerTabsComponent} from './file-manager-tabs/file-manager-tabs.component';
import {FileManagerRoutingModule} from './file-manager-routing.module';
import {HomeFileManagerComponent} from './home-file-manager/home-file-manager.component';

import {FileListComponent} from './file-list/file-list.component';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {CustomPaginator} from './file-list/customPaginatorClass';
import {CommonModule} from '@angular/common';

const PAGES_COMPONENTS = [FileManagerTabsComponent, HomeFileManagerComponent, FileListComponent];

const WEBSERVICES = [];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FileManagerRoutingModule,
    MatSortModule,
    MatPaginatorModule,
    ...PAGES_COMPONENTS
  ],
  providers: [...WEBSERVICES, {provide: MatPaginatorIntl, useClass: CustomPaginator}],
  exports: []
})
export class FileManagerModule {}
