import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileListService {
  private readonly fileList = new BehaviorSubject([]);
  public fileListMessager = this.fileList.asObservable();
  private readonly dataIsLoading = new BehaviorSubject(true);
  public isLoadingMessager = this.dataIsLoading.asObservable();

  changeFileList(newList) {
    this.fileList.next(newList);
  }

  changeIsLoading(bool: boolean) {
    this.dataIsLoading.next(bool);
  }
}
