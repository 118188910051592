import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatSort, MatSortModule} from '@angular/material/sort';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import {FilesWebservice} from 'src/app/services/webservices/files.webservice';
import {MatDialog} from '@angular/material/dialog';
import {DialogEditNameComponent} from '../../shared/dialog/dialog-edit-name/dialog-edit-name.component';
import {DialogShowDocsComponent} from '../../shared/dialog/dialog-show-docs/dialog-show-docs.component';
import {FileInfoDTO} from 'src/app/model/dto/file-info.dto';
import {AlertService} from 'src/app/services/alert.service';
import {DialogDefaultComponent} from '../../shared/dialog/dialog-default/dialog-default.component';
import {Destroyed} from '../../shared/directives/destroyed.directive';
import {CurrentUserService} from 'src/app/services/current-user.service';
import {ModuleSource} from '../../../model/enums/source/module-source.enum';
import {CustomDateAdapter} from '../../../utils/custom-date-adapter';
import {DatePipe, NgIf} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatToolbarModule} from '@angular/material/toolbar';
import {AlertComponent} from '../../shared/alert/alert/alert.component';
import {isNotNullOrUndefined} from '../../../utils/utils.static';

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE]
    }
  ],
  standalone: true,
  imports: [
    AlertComponent,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatTableModule,
    MatSortModule,
    MatButtonToggleModule,
    MatIconModule,
    NgIf,
    MatPaginatorModule,
    DatePipe
  ]
})
export class FileListComponent extends Destroyed implements OnInit, OnChanges {
  @Input() listDBFiles: FileInfoDTO[];
  @Input() idProgram: number;

  @Output() changes = new EventEmitter();

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  imageFileExtensions: string[] = ['jpg', 'jpeg', 'bmp', 'gif', 'png', 'doc', 'docx'];

  isAlreadyClicked = false;
  isAlreadyClickedDownload = false;

  public displayedColumns;
  public dataSource = new MatTableDataSource<FileInfoDTO>();

  constructor(
    private readonly _adapter: DateAdapter<unknown>,
    private readonly currentUserService: CurrentUserService,
    private readonly filesWebservice: FilesWebservice,
    private readonly dialog: MatDialog,
    private readonly alertService: AlertService
  ) {
    super();
  }

  ngOnInit() {
    this._adapter.setLocale('fr-FR');

    this.setAffichage();
    this.getFiles();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges() {
    this.getFiles();
  }

  onResize() {
    this.setAffichage();
  }

  setAffichage() {
    this.displayedColumns = ['name', 'size', 'creationDate', 'buttons'];
  }

  editFileName(file: FileInfoDTO) {
    const dialogRef = this.dialog.open(DialogEditNameComponent);

    dialogRef.componentInstance.idProgram = this.idProgram;
    dialogRef.componentInstance.file = file;

    dialogRef
      .afterClosed()
      .pipe(this.untilDestroyed())
      .subscribe((result) => {
        if (isNotNullOrUndefined(result)) {
          this.filesWebservice
            .renameFile(this.idProgram, file.fileName, result)
            .pipe(this.untilDestroyed())
            .subscribe(() => {
              this.changes.emit();
            });
        }
      });
  }

  deleteFile(file: FileInfoDTO) {
    const dialogRef = this.dialog.open(DialogDefaultComponent);

    dialogRef.componentInstance.message = `Souhaitez-vous vraiment supprimer le document ${file.fileName} ?`;
    dialogRef.componentInstance.title = 'Suppression du document';
    dialogRef
      .afterClosed()
      .pipe(this.untilDestroyed())
      .subscribe((result) => {
        if (result) {
          this.filesWebservice
            .deleteFile(file.fileName, this.idProgram)
            .pipe(this.untilDestroyed())
            .subscribe(() => {
              this.changes.emit();
            });
        }
      });
  }

  doFilterName(event) {
    this.dataSource.filterPredicate = (data, filtre) => {
      return data.fileName.toLocaleLowerCase().includes(filtre);
    };
    this.dataSource.filter = event.target.value.trim().toLocaleLowerCase();
  }

  doFilterDate(value) {
    this.dataSource.filterPredicate = (data, filtre) => {
      return data.dateCreated.toLocaleDateString().includes(filtre);
    };
    if (value) {
      this.dataSource.filter = value._d.toLocaleDateString();
    } else {
      this.dataSource.filter = '';
    }
  }

  canAccess() {
    return this.currentUserService.hasModule(ModuleSource.ADMINISTRATION);
  }

  onFileClick(file: FileInfoDTO) {
    this.isAlreadyClicked = true;

    if (file.fileType === 'doc' || file.fileType === 'docx' || file.fileType === 'pdf') {
      this.alertService.clear();
      this.alertService.warn(
        "Le fichier n'est pas visualisable sur le site, veuillez le télécharger pour le consulter (Word et PDF)",
        `alert-prog-${this.idProgram}`
      );
      this.isAlreadyClicked = false;
    } else if (this.imageFileExtensions.some((i) => i === file.fileType)) {
      const dialogRef = this.dialog.open(DialogShowDocsComponent, {
        data: file,
        panelClass: 'dialog-large'
      });
      dialogRef
        .afterClosed()
        .pipe(this.untilDestroyed())
        .subscribe((res) => {
          this.isAlreadyClicked = false;
        });
    } else {
      this.alertService.clear();
      this.alertService.error(
        "Le fichier n'est pas dans les formats acceptés : JPG, BMP, GIF, PNG",
        `alert-prog-${this.idProgram}`
      );
      this.isAlreadyClicked = false;
    }
  }

  downloadFile(file) {
    if (!this.isAlreadyClickedDownload) {
      this.isAlreadyClickedDownload = true;
      this.filesWebservice
        .downloadFile(file.fileName, this.idProgram)
        .pipe(this.untilDestroyed())
        .subscribe((fileb: Blob) => {
          const url = window.URL.createObjectURL(fileb);
          const link = document.createElement('a');
          link.download = 'BOOK_SPORT-ET-PEDAGOGIE.pdf';
          link.href = url;
          link.click();
        })
        .add(() => {
          this.isAlreadyClickedDownload = false;
        });
    }
  }

  // Récupère les fichiers depuis le service
  private getFiles() {
    this.dataSource.data = this.listDBFiles;
  }
}
