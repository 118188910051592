<div class="row-container">
  <div class="flex-row margin-auto d-flex h-100 justify-content-center align-items-center flex-wrap pt-3">
    <app-alert id="alert-prog-{{idProgram}}"></app-alert>
  </div>
  <mat-toolbar class="sub_header flex-row margin-auto d-flex h-100 justify-content-start align-items-center flex-wrap">

    <div class="search-field d-flex flex-row align-items-center my-2 mx-2">
      <p class="col m-0 p-0">Recherche par nom : </p>
      <mat-form-field class="col">
        <input (keyup)="doFilterName($event)"
               class="search-field pl-2 justify-content-center align-items-center" matInput
               type="text">
      </mat-form-field>
    </div>

    <div class="d-flex flex-row align-items-center my-2 mx-2" style="width: auto; max-width: 100%">
      <p class="col m-0 p-0">Recherche par date : </p>
      <mat-form-field class="col">
        <input (dateChange)="doFilterDate($event.target.value)" (dateInput)="doFilterDate($event.target.value)"
               [matDatepicker]="picker"
               class="pl-2 justify-content-center align-items-center" matInput>
        <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

  </mat-toolbar>


  <div class="flex-row margin-auto">
    <table [dataSource]="dataSource" mat-table matSort>
      <caption></caption>
      <ng-container matColumnDef="name">
        <th *matHeaderCellDef mat-header-cell mat-sort-header scope="col"> Nom du document</th>
        <td (click)="onFileClick(file)" *matCellDef="let file" class="preview" mat-cell> {{ file.fileName }} </td>
      </ng-container>

      <ng-container class="wrapper" matColumnDef="size">
        <th *matHeaderCellDef mat-header-cell mat-sort-header scope="col"> Taille</th>
        <td *matCellDef="let file" mat-cell> {{ (file.fileSize / 1024000).toPrecision(3) + ' Mo' }} </td>
      </ng-container>

      <ng-container class="wrapper" matColumnDef="creationDate">
        <th *matHeaderCellDef mat-header-cell mat-sort-header scope="col"> Dernière modification</th>
        <td *matCellDef="let file" mat-cell> {{ file.dateCreated | date}} </td>
      </ng-container>


      <ng-container class="wrapper" matColumnDef="buttons">
        <th *matHeaderCellDef mat-header-cell scope="col"></th>
        <td *matCellDef="let file" class="bouton" mat-cell>
          <mat-button-toggle-group>
            <mat-button-toggle (click)="downloadFile(file)" class="btn-primary center"
                               value="download">
              <mat-icon class="mat-18">cloud_download</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle (click)="editFileName(file)" *ngIf="canAccess()" class="btn-secondary center"
                               value="edit">
              <mat-icon class="mat-18">edit</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle (click)="deleteFile(file)" *ngIf="canAccess()" class="btn-danger center"
                               value="delete">
              <mat-icon class="mat-18">delete</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </td>
      </ng-container>


      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>

  </div>
  <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="10">
  </mat-paginator>
</div>
