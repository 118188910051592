import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {FilesWebservice} from '../../../services/webservices/files.webservice';
import {FileListService} from '../../../services/file-list.service';
import {FileInfoDTO} from 'src/app/model/dto/file-info.dto';
import {Destroyed} from '../../shared/directives/destroyed.directive';
import {CurrentUserService} from '../../../services/current-user.service';
import {ModuleSource} from '../../../model/enums/source/module-source.enum';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {FileListComponent} from '../file-list/file-list.component';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {DragDropDirective} from '../../shared/directives/drag-drop.directive';
import {MatCardModule} from '@angular/material/card';
import {NgFor, NgIf} from '@angular/common';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-home-file-manager',
  templateUrl: './home-file-manager.component.html',
  styleUrls: ['./home-file-manager.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatCardModule,
    DragDropDirective,
    MatButtonModule,
    MatIconModule,
    NgFor,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    FileListComponent,
    MatProgressSpinnerModule
  ]
})
export class HomeFileManagerComponent extends Destroyed implements OnInit, OnChanges {
  @Input() idProgram: number;
  // Fichiers récupérés par la dropzone ou le button
  public listOfFilesAdded = [];
  // Noms des fichiers sur la DB
  listDBFiles: FileInfoDTO[] = [];
  validExtensions: string[] = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'];
  controls: UntypedFormArray;
  isLoading: boolean;
  public currentWindowWidth: number;

  constructor(
    private readonly dialog: MatDialog,
    private readonly currentUserService: CurrentUserService,
    private readonly filesWebservice: FilesWebservice,
    private readonly fileListservice: FileListService,
    private readonly toastrService: ToastrService
  ) {
    super();
  }

  ngOnInit() {
    this.fileListservice.isLoadingMessager.pipe(this.untilDestroyed()).subscribe((val) => {
      this.isLoading = val;
    });
    this.fileListservice.changeIsLoading(true);
    this.getFiles();
  }

  ngOnChanges() {
    this.getFiles();
  }

  onFileAdded($event: File[]) {
    const tempListOfFilesAdded = [];
    this.listOfFilesAdded.forEach((f) => tempListOfFilesAdded.push(f));
    for (const file of $event) {
      tempListOfFilesAdded.push(file);
    }
    if (this.checkExtension(tempListOfFilesAdded)) {
      this.toastrService.error(
        "Un ou plusieurs fichiers n'ont pas le bon format. Seuls les types de fichiers suivants sont acceptés :\n● pdf\n● jpg\n● jpeg\n● png\n● pdf\n● word",
        'Format incorrect'
      );
    } else {
      this.listOfFilesAdded = tempListOfFilesAdded;
      this.createForm();
    }
  }

  createForm() {
    const toGroups = this.listOfFilesAdded.map((file) => {
      // On retire l'extension du fichier
      const fileNameWithoutExtArray = file.name.split('.');
      fileNameWithoutExtArray.pop();

      // On recrée le nom à partir de l'array (dans le cas où le nom contient des .)
      const fileNameWithoutExt = fileNameWithoutExtArray.join('.');
      return new UntypedFormGroup({
        name: new UntypedFormControl(fileNameWithoutExt, Validators.required)
      });
    });
    this.controls = new UntypedFormArray(toGroups);
  }

  onNameEdited(index) {
    // On récupère l'extension du fichier
    const fileExt = this.listOfFilesAdded[index].name.split('.').pop();

    // On crée un nouveau File car la classe File n'a pas de setter .-.
    const myFile = this.listOfFilesAdded[index];
    const myNewFile = new File([myFile], this.controls.controls[index].value.name + '.' + fileExt, {
      type: myFile.type
    });
    this.listOfFilesAdded[index] = myNewFile;
  }

  onRegisterFiles() {
    const takenNames = [];
    this.controls.controls.forEach((c) => {
      if (this.listDBFiles.find((f) => f.fileName === c.value.name) !== undefined) {
        takenNames.push(c.value.name);
      }
    });
    if (this.controls.valid) {
      if (takenNames.length >= 1) {
        // Pop-up pour dire que certains noms sont déjà pris + les afficher
        let errorMessage =
          'Les noms suivants existent déjà en base, veuillez renommer les documents concernés :';
        takenNames.forEach((name) => {
          errorMessage += '\n● ' + name;
        });
        this.toastrService.error(errorMessage, "Les documents n'ont pas pu être enregistrés");
      } else {
        // On vérifie que plusieurs fichiers n'ont pas le même nom
        if (this.checkDuplicate()) {
          this.toastrService.error(
            'Plusieurs fichiers ont le même nom, veuillez renommer les documents concernés.',
            "Les documents n'ont pas pu être enregistrés"
          );
        } else {
          // On ajoute les fichiers sur Azure
          this.addFiles();
        }
      }
    } else {
      // On vérifie si le même nom a été entré deux fois
      this.toastrService.error(
        'Certains champs sont invalides, merci de vérifier votre saisie',
        "Les documents n'ont pas pu être enregistrés"
      );
    }
  }

  addFiles() {
    let fileUploaded = true;
    this.listOfFilesAdded.forEach((f) => {
      const formData = new FormData();
      formData.append('file', f);
      this.filesWebservice
        .postUploadFile(this.idProgram, formData)
        .pipe(this.untilDestroyed())
        .subscribe({
          next: () => {
            this.getFiles();
          },
          error: () => {
            fileUploaded = false;
          }
        });
    });
    if (fileUploaded) {
      this.toastrService.success('Les documents ont bien été sauvegardés.', 'Succès');
      this.listOfFilesAdded = [];
    } else {
      this.toastrService.error("Les documents n'ont pas pu être enregistrés.", 'Erreur');
    }
  }

  checkDuplicate() {
    let isDuplicate = false;
    for (let i = 0; i < this.controls.controls.length; i++) {
      for (let j = 0; j < this.controls.controls.length; j++) {
        if (
          i !== j &&
          this.controls.controls[i].value.name === this.controls.controls[j].value.name
        ) {
          isDuplicate = true;
        }
      }
    }
    return isDuplicate;
  }

  checkExtension(listOfFiles) {
    let isExtensionInvalid = false;
    listOfFiles.forEach((f) => {
      const ext: string = f.name.split('.').pop();
      if (!this.validExtensions.find((e) => e === ext.toLowerCase())) {
        isExtensionInvalid = true;
      }
    });
    return isExtensionInvalid;
  }

  getControl(index: number, field: string) {
    return this.controls.at(index).get(field);
  }

  getFiles() {
    this.fileListservice.changeIsLoading(true);
    this.fileListservice.changeFileList([]);
    this.listDBFiles = [];
    this.filesWebservice
      .getFilesInfo(this.idProgram)
      .pipe(this.untilDestroyed())
      .subscribe((data: FileInfoDTO[]) => {
        if (data && data.length > 0) {
          data.forEach((fileInfo) => {
            // On enlève le préfixe (nom du répertoire) dans l'URI
            const name = fileInfo.fileName;
            // On enlève l'extension
            const nameWithoutExt = name.split('.');
            const extension = nameWithoutExt.pop();
            fileInfo.fileType = extension;
            this.listDBFiles.push(fileInfo);
          });
        }
        this.fileListservice.changeIsLoading(false);
      });
  }

  canAccess() {
    return this.currentUserService.hasModule(ModuleSource.ADMINISTRATION);
  }

  onListChanges() {
    this.getFiles();
  }

  deleteFile(index: number) {
    this.listOfFilesAdded.splice(index, 1);
    this.createForm();
  }
}
