import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {AlertService} from '../../../../services/alert.service';
import {MatDialogRef, MatDialogTitle} from '@angular/material/dialog';
import {FileBlob} from 'src/app/model/file-blob.model';
import {FilesWebservice} from 'src/app/services/webservices/files.webservice';
import {FileInfoDTO} from 'src/app/model/dto/file-info.dto';
import {Destroyed} from '../../directives/destroyed.directive';
import {MatIconModule} from '@angular/material/icon';
import {AlertComponent} from '../../alert/alert/alert.component';
import {InputTextComponent} from '../../form-parts/form-items/input-text/input-text.component';

@Component({
  selector: 'app-dialog-edit-name',
  templateUrl: './dialog-edit-name.component.html',
  styleUrls: ['./dialog-edit-name.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    FormsModule,
    ReactiveFormsModule,
    InputTextComponent,
    AlertComponent,
    MatIconModule
  ]
})
export class DialogEditNameComponent extends Destroyed implements OnInit, OnDestroy {
  result = false;
  formGroup: UntypedFormGroup;

  // Noms des fichiers sur la DB
  listDBFiles = [];
  public currentWindowWidth: number;

  file: FileInfoDTO;
  idProgram: number;

  constructor(
    public readonly dialogRef: MatDialogRef<DialogEditNameComponent>,
    private readonly fb: UntypedFormBuilder,
    private readonly alertService: AlertService,
    private readonly filesWebService: FilesWebservice
  ) {
    super();
  }

  ngOnInit() {
    this.currentWindowWidth = window.innerWidth;
    this.formGroup = this.fb.group({
      name: [this.file.fileName.split('.')[0], Validators.required]
    });

    this.filesWebService
      .getFilesInfo(this.idProgram)
      .pipe(this.untilDestroyed())
      .subscribe((data: FileInfoDTO[]) => {
        data.forEach((info) => {
          // On enlève le préfixe (nom du répertoire) dans l'URI
          const name = info.fileName.split('/');
          name.shift();
          // On enlève l'extension
          const nameWithoutExt = name.join('/').split('.');
          const extension = nameWithoutExt.pop();
          const file: FileBlob = {
            name: nameWithoutExt.join('.'),
            size: info.fileSize,
            creationDate: new Date(info.dateCreated),
            fullName: info.fileName,
            extension
          };
          this.listDBFiles.push(file);
        });
      });
  }

  yes() {
    // On vérifie que l'utilisateur a bien entré un nom
    if (this.formGroup.valid) {
      // Si le nom est le même que l'ancien, on renvoie null (pas de modifications)
      if (this.formGroup.value.name === this.file.file.name) {
        this.dialogRef.close(null);
      } else {
        // On vérifie que le nom n'existe pas déjà sur Azure
        if (this.listDBFiles.find((f) => f.fileName === this.formGroup.value.name) !== undefined) {
          this.alertService.clear();
          this.alertService.error('Le nom existe déjà en base, veuillez le modifier.');
        } else {
          this.result = true;
          this.dialogRef.close(this.formGroup.value.name);
        }
      }
    } else {
      this.alertService.clear();
      this.alertService.error('Veuillez saisir un nom');
    }
  }

  no() {
    this.result = false;
    this.dialogRef.close(null);
  }

  ngOnDestroy() {
    this.alertService.clear();
    if (this.result === false) {
      this.dialogRef.close(null);
    }
  }
}
