<div class="dialog-default">
  <h1 mat-dialog-title>Éditer le nom du fichier</h1>
  <form [formGroup]="formGroup" class="d-flex flex-column">
    <app-input-text (keyup.enter)="yes()" [control]="formGroup.controls.name" [group]="formGroup" class="px-3"
                    fcName="name" textLabel="Nom :">
    </app-input-text>
    <small class="text-muted px-3 pb-2">Le nom du fichier ne doit pas comporter d'extension</small>
    <app-alert></app-alert>
    <div class="d-flex flex-row justify-content-end align-items-center">
      <button (click)="no()" class="cancel-normal btn btn-secondary my-2 mx-2 text-center" type="button">Annuler
      </button>
      <button (click)="no()" class="cancel-icon btn btn-secondary my-2 mx-2" type="button">
        <mat-icon style="vertical-align: middle">cancel</mat-icon>
      </button>
      <button (click)="yes()" class="register-normal btn btn-primary my-2 mx-2" type="button">Enregistrer</button>
      <button (click)="yes()" class="register-icon btn btn-primary my-2 mx-2" type="button">
        <mat-icon style="vertical-align: middle">save</mat-icon>
      </button>
    </div>
  </form>
</div>


