<mat-tab-group (selectedTabChange)="tabChanged($event)" [selectedIndex]="defaultIndex" mat-align-tabs="start" mat-stretch-tabs="false">
  <mat-tab label="Sport dans la Ville">
  </mat-tab>

  <mat-tab label="Réussite dans la Ville">
  </mat-tab>

  <mat-tab label="L dans la Ville">
  </mat-tab>

  <mat-tab label="Entrepreneur dans la Ville">
  </mat-tab>

  <mat-tab label="Job dans la Ville">
  </mat-tab>

</mat-tab-group>


<app-home-file-manager [idProgram]="selectedTab"></app-home-file-manager>
