<mat-card appearance="outlined" *ngIf="canAccess()" class="mat-card-container my-2 mx-0 m-md-4 p-2 p-md-3">
  <div class="container">
    <div class="d-flex flex-row flex-wrap">
      <div class="col-12">
        <div class="row justify-content-center">

          <div class="col-12 ajout-document px-2 py-4">
            <h4 class="text-center text-primary">Ajouter des documents</h4>
            <hr>

            <!-- Dropzone + bouton d'ajout -->
            <div (fileDropped)="onFileAdded($event)" appDragDrop class="m-1 p-3 dropzone">
              <div class="mt-4 align-items-center justify-content-center row-container flex-wrap align-content-center">
                <div class="my-4 text-center">
                  <p class="tiny-text">Faites glisser vos documents ici <br> ou</p>
                </div>
                <div class="row align-items-center justify-content-center">
                  <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 text-center">
                    <button (click)="fileInput.click()" class="col btn btn-primary" mat-raised-button>
                      <mat-icon class="icon mr-3">folder_open</mat-icon>
                      Importer des documents
                      <input #fileInput (change)="onFileAdded($event.target.files)" multiple style="display:none;"
                             type="file"/>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Liste des fichiers + édition avant insertion en base -->
            <div *ngIf="listOfFilesAdded.length > 0" class="mt-4">
              <div class="row-container align-content-center">
                <div *ngFor="let file of listOfFilesAdded; let index = index" class="row flex-wrap mx-4 p-1">
                  <mat-icon class="d-none d-md-inline col-1 align-self-center">insert_drive_file</mat-icon>
                  <mat-form-field class="col-7 col-md-8">
                    <mat-label>Nom du document</mat-label>
                    <input (change)="onNameEdited(index)" [formControl]="getControl(index, 'name')" matInput
                           placeholder="Nom du document">
                  </mat-form-field>
                  <div class="taille col-3 col-md-2">
                    <div>Taille :</div>
                    <div class="tiny-text ">{{ file.size }} Mo</div>
                  </div>
                  <div class="col-1">
                    <mat-icon (click)="deleteFile(index)" class="align-self-center btn">close</mat-icon>
                  </div>
                </div>
              </div>
              <div class="row align-items-center justify-content-center">
                <div class="col-8 col-sm-6 col-md-4 col-lg-3">
                  <button (click)="onRegisterFiles()" class="col btn btn-primary" mat-stroked-button>
                    <mat-icon class="mr-3">cloud_upload</mat-icon>
                    Enregistrer
                  </button>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>

<div *ngIf="!isLoading && this.listDBFiles.length > 0" id="grid-wrapper">
  <mat-card appearance="outlined" class="m-2 m-md-4 p-0">
    <app-file-list (changes)="onListChanges()" [idProgram]="idProgram" [listDBFiles]="listDBFiles"></app-file-list>
  </mat-card>
</div>


<div *ngIf="isLoading">
  <div class="d-flex flex-row justify-content-center align-items-center py-5">
    <label>Chargement en cours...</label>
  </div>
</div>

<div *ngIf="listDBFiles && listDBFiles.length === 0 && !isLoading">
  <mat-card appearance="outlined" class="m-4">
    <h5 class="m-2">Aucun fichier n'est disponible pour le moment.</h5>
  </mat-card>
</div>



